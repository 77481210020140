<template>
  <div id="employeeReactivation" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-5"
        >REACTIVACIÓN DE EMPLEADO</v-row
      >
      <v-container fluid class="addCategory">
        <v-row no-gutters justify="end">
          <v-col cols="12" lg="5" md="5" sm="5" class="ml-5">
            <v-row>
              <div class="form-group mt-7 mb-1">
                <label for="selectCompany" class="mr-9">Empresa Pagadora</label>
                <select
                  name="selectCompany"
                  id="selectCompany"
                  class="ml-2 selectForm"
                  v-model="enterprise"
                >
                  <option disabled value="0">Selecciona Empresa</option>
                  <option
                    v-for="company in enterprises"
                    :key="company.id"
                    v-bind:value="company.id"
                  >
                    {{ company.razonSocial }}
                  </option>
                </select>
              </div>
            </v-row>
            <v-row>
              <div class="form-group mt-1">
                <label for="employeeNumber" class="mr-7"
                  >Número de Empleado</label
                >
                <input
                  type="text"
                  name="employeeNumber"
                  id="employeeNumber"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="employeeNumber"
                  required
                  maxlength="20"
                  @keypress="isNumber($event)"
                />
              </div>
            </v-row>
            <v-row>
              <div class="form-group mt-3">
                <label for="rfc" style="margin-right: 140px">RFC</label>
                <input
                  type="text"
                  name="rfc"
                  id="rfc"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="rfc"
                  required
                  maxlength="20"
                />
              </div>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            lg="6"
            md="6"
            sm="6"
            class="mt-5"
            align-self="center"
          >
            <button class="botonAmarillo" @click="validacion()">Guardar</button>
            <router-link to="/employeeBlacklists" id="backEmployee">
              <button class="botonAmarilloDerecho">Regresar</button>
            </router-link>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Reactivacion de Empleado</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-sm-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
          <ul>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Aceptación de acciones-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Aviso</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 style="text-align: center">{{ respuesta }}</h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="cancel()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="reactivar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Reactivación de Empleado</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="aux()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      enterprises: [],
      errors: [],
      error: false,
      confirmation: false,
      respuesta: "",
      advice: false,
      enterprise: localStorage.empresaIdGlobal,
      rfc: "",
      employeeNumber: 0,
    };
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode > 31 && charCode < 48) || charCode > 57) {
        evt.preventDefault();
      } else return true;
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    validaRfc() {
      this.errors = [];
      var strCorrecta;
      strCorrecta = this.rfc;
      if (this.rfc.length == 12) {
        var valid = "^(([A-Z]|[a-z]){3})([0-9]{6})((([A-Z]|[a-z]|[0-9]){3}))";
      } else {
        var valid =
          "^(([A-Z]|[a-z]|s){1})(([A-Z]|[a-z]){3})([0-9]{6})((([A-Z]|[a-z]|[0-9]){3}))";
      }
      var validRfc = new RegExp(valid);
      var matchArray = strCorrecta.match(validRfc);
      if (matchArray == null) {
        this.errors.push("El RFC es incorrecto favor de verificarlo.");
        return false;
      } else {
        console.log("Correcto");
        return true;
      }
    },
    validacion() {
      this.validaRfc();
      if (this.enterprise == "" || this.enterprise == 0) {
        this.errors.push("Se debe especificar la empresa.");
      }
      if (this.employeeNumber == "" || this.employeeNumber == 0) {
        this.errors.push("Se debe especificar el número de empleado.");
      }
      if (this.errors.length == 0) {
        this.respuesta = "Esta seguro de la reactivación del empleado.";
        this.advice = true;
      } else {
        this.respuesta = "Por favor corrige el siguiente error:";
        this.error = true;
      }
    },
    reactivar() {
      this.advice = false;
      this.show = true;
      axios
        .get(
          Server +
            "/empleados/reactivacion" +
            "?empresaId=" +
            this.enterprise +
            "&empleadoId=" +
            this.employeeNumber +
            "&rfc=" +
            this.rfc,
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.show = false;
          //console.log(response);
          this.employeeNumber = "";
          this.company = "";
          this.rfc = "";
          this.respuesta = "El empleado fue reactivado con éxito";
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.employeeNumber = "";
            this.company = "";
            this.rfc = "";
            this.respuesta =
              "Los datos ingresados son incorrectos o el empleado se encuentra activo";
            this.confirmation = true;
          }
        });
    },
    cancel() {
      this.advice = false;
      this.rfc = "";
      this.employeeNumber = "";
      this.company = "";
    },
    aux() {
      this.enterprise = 0;
      this.rfc = "";
      this.employeeNumber = "";
      this.confirmation = false;
      //this.$router.go(-1);
    },
  },
  created() {
    this.listar();
  },
};
</script>